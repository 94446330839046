<template>
  <div class="white-slider">
    <input
      type="range"
      :value="value"
      :min="min"
      :max="max"
      :step="step"
      @input="inputChange"
      @change="handleChange"
    />
  </div>
</template>

<script>
export default {
  name: 'WhiteSlider',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {

    }
  },
  methods: {
    inputChange(e) {
      const v = parseFloat(e.target.value)
      this.$emit('input', v)
    },
    handleChange(e) {
      const v = parseFloat(e.target.value)
      this.$emit('input', v)
      this.$emit('change', v)
    }
  }
}
</script>

<style lang='less' scoped>
.white-slider {
  width: 100%;
  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    margin: 11px 0;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    cursor: pointer;
    background: #fff;
    border: 0 solid #fff;
    border-radius: 255px;
    width: 100%;
    height: 4px;
    box-shadow: 0 0 1px #000, 0 0 #0d0d0d;
  }

  input[type="range"]::-webkit-slider-thumb {
    cursor: pointer;
    -webkit-appearance: none;
    background: #fff;
    border: 1px solid #c8c8c8;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-top: -11px;
    box-shadow: 0 0 #000, 0 0 #0d0d0d;
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #fff;
  }

  input[type="range"]::-moz-range-track {
    cursor: pointer;
    background: #fff;
    border: 0 solid #fff;
    border-radius: 255px;
    width: 100%;
    height: 2px;
    box-shadow: 0 0 1px #000, 0 0 #0d0d0d;
  }

  input[type="range"]::-moz-range-thumb {
    cursor: pointer;
    background: #fff;
    border: 1px solid #c8c8c8;
    border-radius: 4px;
    width: 12px;
    height: 24px;
    box-shadow: 0 0 #000, 0 0 #0d0d0d;
  }

  input[type="range"]::-ms-track {
    cursor: pointer;
    color: transparent;
    background: 0 0;
    border-color: transparent;
    width: 100%;
    height: 2px;
  }

  input[type="range"]::-ms-fill-lower {
    background: #f2f2f2;
    border: 0 solid #fff;
    border-radius: 510px;
    box-shadow: 0 0 1px #000, 0 0 #0d0d0d;
  }

  input[type="range"]::-ms-fill-upper {
    background: #fff;
    border: 0 solid #fff;
    border-radius: 510px;
    box-shadow: 0 0 1px #000, 0 0 #0d0d0d;
  }

  input[type="range"]::-ms-thumb {
    cursor: pointer;
    background: #fff;
    border: 1px solid #c8c8c8;
    border-radius: 4px;
    width: 12px;
    height: 2px;
    box-shadow: 0 0 #000, 0 0 #0d0d0d;
  }

  input[type="range"]:focus::-ms-fill-lower {
    background: #fff;
  }

  input[type="range"]:focus::-ms-fill-upper {
    background: #fff;
  }
}
</style>