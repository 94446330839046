import Vue from 'vue';
import messageComponent from './message.vue'; // 引入组件，方便继承

let count = 0; // 定义统计次数，便于知道创建多少个实例

let MessageConstructor = Vue.extend(messageComponent); // 引入一个message构造器，方便new之

function cutCount() {
  // 当message消失一个
  count = count - 1; // 就把外界统计的数量减少一个
  let messageBoxDomList = document.querySelectorAll('.messageBox'); // 然后选中所有的messageDOM元素
  for (let i = 0; i < messageBoxDomList.length; i++) {
    // 遍历一下这个DOM伪数组
    let dom = messageBoxDomList[i]; // 所有的都往上移动60像素
    dom.style['top'] = parseInt(dom.style['top']) - 60 + 'px';
  }
}

const createMyMessage =
  type =>
  (options = {}) => {
    if (typeof options === 'string') {
      options = { message: options };
    }
    const { duration = 3000, ...otherOptions } = options;
    if (typeof duration !== 'number') {
      console.error('Error! duration must be a number');
      return;
    }

    count += 1;
    const instance = new MessageConstructor({
      data: {
        ...otherOptions,
        type: type || otherOptions.type,
        duration: duration,
        message: options.message || ''
      },
      propsData: {
        count: count,
        cutCount
      }
    });

    instance.$mount();
    document.body.appendChild(instance.$el);
    instance.isShowMyMessage = true;
    return instance;
  };

const MyMessage = (options = {}) => {
  if (typeof options === 'string') {
    // 如果传入的是字符串，则认为是类型名称
    // const type = options;
    return createMyMessage('info')(options);
  } else {
    // 否则，处理对象配置
    const { type = 'info', ...otherOptions } = options;
    return createMyMessage(type)(otherOptions);
  }
};

// 创建不同的消息类型
const success = createMyMessage('success');
const info = createMyMessage('info');
const warning = createMyMessage('warning');
const error = createMyMessage('error');

// 导出这些方法
const MessageEl = {
  install(Vue, options) {
    function MyMessageInstall(content, duration) {
      return MyMessageInstall.default.call(this, content, duration)
    }
    MyMessageInstall.default = MyMessage
    Object.assign(MyMessageInstall, {
      success,
      info,
      warning,
      error
    })
    Vue.prototype.$message = MyMessageInstall;
  }
};

Vue.use(MessageEl);
