import { http } from '@/utils/http';
import store from '../store/index'

// 获取音频列表
export function GetSearchData() {
  return http({
    url: '/sound/v1/getIp',
    method: 'get',
  });
}

// 获取音频列表
export function GetAudioList(data = {}) {
  return http({
    url: '/sound/v1/querySound',
    method: 'post',
    data
  });
}

// 获取用户信息
export function GET_USER_INFO (data) {
  const resdata = data
  const url = 'na/auth/usercenter'
  return http({
    url,
    method: 'post',
    data: resdata
  }).then((res) => {
    if (res.code === 200) {
      if (res.data?.token) {
        window.localStorage.setItem('token', res.data.token)
      }
      if (res.data.user_id) {
        res.data.id = res.data.user_id
      }
      window.localStorage.setItem('userInfo', JSON.stringify(res.data))
      store.commit('setUserInfo', res.data)
    }
    return Promise.resolve(res)
  }).catch((err) => Promise.reject(err))
}

// 混合音乐增删改查
export function OptionUserSound(data = {}) {
  // option 0，查询，
  // 1 新增/修改 （需要传id，查询的时候我会给你，没有id或者id=0我就认为是修改，有id我就会根据id 进行修改）
  // 3.删除传id
  return http({
    url: '/sound/v1/queryUserSound',
    method: 'post',
    data
  });
}