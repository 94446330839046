import { http } from '@/utils/http'

// 获取短信验证码
export function GET_CODE (data) {
  return http({
    url: '/na/auth/sms',
    method: 'post',
    data
  })
}

// 短信验证码登录
export function USER_LOGIN (data) {
  return http({
    url: '/na/auth/smslogin',
    method: 'post',
    data
  })
}

// 绑定手机
export function PHONE_BIND (data) {
  return http({
    url: '/na/auth/bindphone',
    method: 'post',
    data
  })
}

