/*
 * @Author: your name
 * @Date: 2021-12-10 16:44:17
 * @LastEditTime: 2024-07-25 17:43:26
 * @LastEditors: 张厚根 1072901035@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \zhuishu-pc\src\utils\storage.js
 */

// 设置token
export function SET_TOKEN (token) {
  window.localStorage.setItem('token', token)
}

// 获取token
export function GET_TOKEN () {
  return window.localStorage.getItem('token')
}

// 删除token
export function REMOVE_TOKEN () {
  return window.localStorage.removeItem('token')
}

// 设置用户信息
export function SET_USER_INFO (info) {
  window.localStorage.setItem('userInfo', info)
}

// 获取用户信息
export function GET_USER_INFO () {
  let obj = window.localStorage.getItem('userInfo')
  obj = JSON.parse(obj || '{}')
  if (obj && obj.user_id) {
    obj.id = obj.user_id
  }
  return obj
}

// 删除用户信息
export function REMOVE_USER_INFO () {
  return window.localStorage.removeItem('userInfo')
}
