<template>
  <div class="MusicPay">
    <div class="pay-code-content" v-if="!paySuccess">
      <div class="pay-code-box">
        <img v-if="codeimg && check" :src="codeimg" alt="" />
      </div>
      <div class="pay-code-info">
        <div class="code-price">
          扫码支付：
          <span
            >￥<i>{{ currentprice }}</i></span
          >
        </div>
        <div class="code-pay-type">
          <img src="@/assets/icons/wx_login.png" alt="" />
          <span>使用微信扫码支付</span>
        </div>
        <div class="pay-tips">
          提示：虚拟商品，不支持退款，最终解释权归深圳千里龙所有
        </div>
        <div class="confirm-info">
          <i class="check" :class="{ checked: check }" @click="checkchange"></i>
          <span>
            同意
            <router-link :to="{ name: 'RechargeAgreement' }" target="_blank"
              >《充值协议》</router-link
            >
            和
            <router-link :to="{ name: 'PrivacyAgreement' }" target="_blank"
              >《隐私协议》</router-link
            >
          </span>
        </div>
      </div>
    </div>

    <p v-else>
      <span>支付成功！</span>
    </p>

    <div class="continueContainer">
      <button class="button cancel" @click="closeEl">关闭</button>
    </div>
  </div>
</template>

<script>
import { PAY_STATUS, CHOOSE_GOODS } from '@/api/pay'
export default {
  name: 'MusicPay',
  props: {
    payData: {
      type: Object,
      default: () => ({})
    },
    userInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      payisload: false,
      currentId: '',
      timer: null,
      codeimg: '',
      paydata: null,
      check: false,
      userinfo: {},
      currentprice: '--',
      paySuccess: false
    }
  },
  created() {
    if (this.userInfo) {
      this.userinfo = this.userInfo
    }
    if (this.payData) {
      // this.topUp()
      this.currentprice = this.payData?.goods_price
      this.currentId = this.payData?.goods_id
      this.$nextTick(() => {
        this.check = true
        this.topUp()
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    closeEl() {
      if (this.paySuccess) {
        this.$router.go(0)
      } else {
        this.$emit('close')
      }
    },
    checkchange() {
      this.check = !this.check
      if (!this.check) {
        clearInterval(this.timer)
        this.timer = null
      } else {
        this.topUp()
      }
    },
    topUp() {
      if (this.payisload || !this.check) {
        return
      }
      this.payisload = true
      const data = {
        goods_id: this.currentId,
        user_id: this.userinfo.id,
        product_type: 'whitenoisepc'
      }
      CHOOSE_GOODS(data).then((res) => {
        if (res.code === 200 && res?.data?.qr_url) {
          this.payisload = false
          this.paydata = res.data
          this.codeimg = res.data.qr_url
          this.timer = setInterval(() => {
            this.getstatus()
          }, 2000)
        } else {
          this.payisload = false
          this.$msg(res.message || '预支付请求失败, 请刷新后重试')
        }
      }).catch(() => {
        this.payisload = false
      })
    },
    async getstatus(type) {
      const data = {
        prepay_id: this.paydata.prepay_id,
        user_id: this.userinfo?.id
      }
      const res = await PAY_STATUS(data)
      if (res.code === 200) {
        this.userinfo.coincount = res.data.coincount || res.data.coins
        this.userinfo.ticket = res.data.ticket
        // if (type === 'close') {
        //   }
        if (res.data.trade_state_desc === '支付成功') {
          // this.getuserinfo()
          this.paydata = null
          clearInterval(this.timer)
          this.timer = null
          this.$message.success(res.data.trade_state_desc)
          this.paySuccess = true

          setTimeout(() => {
            this.closeEl()
          }, 3000);
          // this.closePay()
        }
      }
    },
  }
}
</script>

<style lang='less' scoped>
.MusicPay {
  width: 100%;
  min-height: 2rem;
  margin: 0 auto;
  .pay-code-content {
    width: 100%;
    height: 1.5rem;
    background-color: rgba(221, 221, 221, 0.3);
    border-radius: 0.1rem;
    padding: 0.15rem 0.25rem;
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    margin-bottom: 0.3rem;
    .pay-code-box {
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.26rem;
      background-color: #ddd;
      border-radius: 0.08rem;
      > img {
        width: 100%;
        height: 100%;
        border-radius: 0.08rem;
      }
    }
    .pay-code-info {
      flex: 1;
      padding-top: 0.1rem;
      .code-price {
        display: flex;
        align-items: center;
        // font-size: 0.12rem;
        margin-bottom: 0.14rem;
        > span {
          color: #eabb6a;
          > i {
            font-size: 0.24rem;
            font-weight: 700;
          }
        }
      }
    }
    .code-pay-type {
      display: flex;
      align-items: center;
      // font-size: 0.12rem;
      margin-bottom: 0.12rem;
      > img {
        width: 0.24rem;
        height: 0.24rem;
        margin-right: 0.1rem;
      }
    }
    .pay-tips {
      font-size: 0.12rem;
      margin-bottom: 0.04rem;
    }
  }
  .confirm-info {
    display: flex;
    align-items: center;
    margin-bottom: 0.19rem;
    .check {
      width: 0.12rem;
      height: 0.12rem;
      background-color: #ccc;
      border-radius: 0.02rem;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      margin-right: 0.12rem;
      background-image: url("~@/assets/icons/check.png");
      cursor: pointer;
    }
    .checked {
      background-image: url("~@/assets/icons/checked.png");
    }
    > span {
      font-size: 0.12rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      > a {
        color: #5a6add;
      }
    }
  }

  .continueContainer {
    flex: 1;
    justify-content: center;
    display: flex;
  }
  .continue.button,
  .cancel.button {
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    border-radius: 0.04rem;
    margin: 0.16rem 0;
    padding: 0.16rem 0.8rem;
    font-weight: 700;
    background-color: #969696;
  }
  .continue.button {
    background-color: #009600;
  }
}
</style>