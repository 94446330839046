<template>
  <div class="play-timeout">
    <div class="timeout-layout-set" v-if="!timeId">
      <div class="time-tabs">
        <div
          class="tab"
          v-for="(item, index) in tabs"
          :key="index"
          :class="{ active: item === tab }"
          @click="tab = item"
        >
          {{ item }}
        </div>
      </div>
      <div class="time-input">
        <div class="time-input-item">
          <input
            type="number"
            min="0"
            max="24"
            v-model.number="hours"
            placeholder="时"
          />
        </div>
        <span>小时</span>
        <div class="time-input-item">
          <input
            type="number"
            min="0"
            max="59"
            v-model.number="minutes"
            placeholder="分"
          />
        </div>
        <span>分钟</span>
      </div>
    </div>
    <div class="timeout-layout" v-else>
      <div class="time-left">{{ formattedTime }}</div>
      <span>{{ tab }}</span>
    </div>
    <footer class="time-footer">
      <button @click="closeEl">关闭</button>
      <button @click="startCountdown" v-if="!timeId">开始计时</button>
      <button @click="stopCountdown" v-else>停止计时</button>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'PlayTimeout',
  data() {
    return {
      tabs: [
        '计时结束停止播放',
        '计时结束开始播放',
        // 'Fade'
      ],
      tab: '计时结束停止播放',
      hours: 0,
      minutes: 0,
      seconds: 0,
      timeId: null,
      countdownInterval: null
    }
  },
  computed: {
    totalSeconds() {
      return this.hours * 3600 + this.minutes * 60 + this.seconds;
    },
    formattedTime() {
      const hours = Math.floor(this.totalSeconds / 3600).toString().padStart(2, '0');
      const minutes = Math.floor((this.totalSeconds % 3600) / 60).toString().padStart(2, '0');
      const seconds = (this.totalSeconds % 60).toString().padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    }
  },
  methods: {
    startCountdown() {
      this.timeId = true;
      this.$emit('change', { time: this.formattedTime, tab: this.tab });
      this.countdownInterval = setInterval(() => {
        if (this.totalSeconds <= 0) {
          clearInterval(this.countdownInterval);
          this.timeId = false;
          return
        };
        this.seconds--;
        if (this.seconds < 0) {
          this.minutes--;
          this.seconds = 59;
        }
        if (this.minutes < 0) {
          this.hours--;
          this.minutes = 59;
        }
        if (this.hours < 0 && this.minutes < 0 && this.seconds < 0) {
          clearInterval(this.countdownInterval);
          this.timeId = false;
        }
        this.$emit('change', { time: this.formattedTime, tab: this.tab });
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.countdownInterval);
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
      this.timeId = false;
      this.$emit('change', { time: this.formattedTime, tab: this.tab });
    },
    closeEl() {
      this.$emit('close')
    }
  }
}
</script>

<style lang='less' scoped>
.play-timeout {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
  .timeout-layout-set {
    width: 100%;
  }
  .timeout-layout {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .time-left {
      background-color: rgba(255, 255, 255, 0.2);
      width: 6em;
      padding: 0.08rem 0.16rem;
      font-size: 0.28rem;
      display: flex;
      border-radius: 0.02rem;
      justify-content: center;
    }
    > span {
      margin-top: 0.08rem;
    }
  }
  .time-tabs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.08rem;
    height: 0.95rem;
    text-align: center;
    .tab {
      border-radius: 0.04rem;
      flex: 1;
      height: 0.48rem;
      line-height: 0.48rem;
      margin: 0 0.08rem;
      font-size: 0.14rem;
      transition: background 0.3s, border 0.3s, padding 0.3s;
      border-color: gray;
      cursor: pointer;
    }
    .active {
      background-color: rgba(255, 255, 255, 0.4);
      border-color: transparent;
    }
  }
  .time-input,
  .time-footer {
    width: 100%;
    display: flex;
    align-items: center;
    height: 0.95rem;
  }
  .time-input,
  .time-tabs {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .time-input {
    justify-content: center;
    .time-input-item {
      > input {
        font-size: 0.16rem;
        line-height: inherit;
        color: #fff;
        text-align: right;
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.7);
        border-radius: 0.04rem;
        width: 0.54rem;
        margin: 0 0.08rem;
        padding: 0.04rem;
      }
    }
  }

  .time-footer {
    justify-content: space-between;
    align-items: center;
    padding: 0 0.16rem;
    button {
      color: #fff;
      border: 0.02rem solid #fff;
      border-radius: 0.04rem;
      padding: 0.08rem 0.16rem;
      transition: color 0.3s, background-color 0.3s;
      cursor: pointer;
      &:hover {
        color: #1f1e21;
        background-color: #fff;
      }
    }
  }
}
</style>