<template>
  <div class="wx-login">
    <div class="login-wx-login">
      <div id="wxlogin"></div>
    </div>
    <!-- <div>
      <OtherLogin class="login_btn" type="wx" />
    </div> -->
  </div>
</template>

<script>
import { WX_DATA } from '@/utils/baseConfig'

export default {
  name: 'WxLogin',
  data () {
    return {

    }
  },
  created () {
    this.$nextTick(() => {
      const redirecturl = window.location.origin + window.location.pathname
      window.WxLogin({
        id: 'wxlogin', // wx组建元素
        appid: WX_DATA.appId, // 微信平台开放id
        scope: 'snsapi_login',
        redirect_uri: encodeURIComponent(redirecturl), // 回调地址 encodeURIComponent编码
        state: '',
        style: 'block', // 黑白样式
        href: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE1MHB4O2JvcmRlci1yYWRpdXM6IDhweDttYXJnaW46IDA7fQouaW1wb3dlckJveCAudGl0bGUge2Rpc3BsYXk6IG5vbmU7fQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZTt9'
      })
    })
  }
}
</script>

<style lang='less' scoped>
.wx-login {
  height: 2.6rem;
  /deep/ iframe {
    display: block;
    margin: 0 auto;
  }
}
</style>
