/**
 * 生成一个唯一的ID字符串
 *
 * 该函数通过结合当前时间、性能计时器和随机数来生成一个唯一的ID
 * 使用了UUID的版本4格式，其中包含时间戳和随机数，以确保ID的唯一性
 *
 * @returns {string} 一个格式如'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'的唯一ID字符串
 */
export function get_unique_id() {
  let d = new Date().getTime();
  let extraEntropy = 0;

  if (window.performance && typeof window.performance.now === 'function') {
    extraEntropy += performance.now();
  }

  // 使用更安全的随机数生成方法
  if (window.crypto && crypto.getRandomValues) {
    const array = new Uint32Array(1);
    crypto.getRandomValues(array);
    extraEntropy += array[0];
  }

  let unique_id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  unique_id = unique_id.replace(/[xy]/g, function (c) {
    let r = (d + extraEntropy + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    extraEntropy = Math.floor(extraEntropy / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  return unique_id;
}

// 截取URL后参数
export function getQueryVariable() {
  const url = window.location.search; // 获取url中"?"符后的字串
  const theRequest = {};
  if (url.indexOf('?') !== -1) {
    const str = url.split('?')[1];
    const str1 = str.split('&');
    str1.forEach(i => {
      const item = i.split('=');
      const key = item[0];
      const value = item[1];
      theRequest[key] = value;
    });
  }
  return theRequest;
}

/**
 * 将声音数组转换为排序后的字符串
 *
 * @param {Array} sounds - 一个数组，包含声音的对象
 * @returns {string} - 按照特定格式和排序规则构造的字符串
 */
export function audioObjectToString(sounds) {
  let result = '';
  const copyArr = [...sounds];
  // 对声音数组按 id 排序并构造字符串
  copyArr
    .sort((a, b) => a.id - b.id)
    .forEach(sound => {
      let volumeStr = sound.volume.toString();

      if (volumeStr !== '0') {
        if (volumeStr.length === 1) {
          volumeStr = '0' + volumeStr;
        }

        if (volumeStr === '100') {
          volumeStr = '99';
        }

        // 使用 '-' 分割 id 和 volume
        result += sound.id + '-' + volumeStr + '-';
      }
    });

  return result.slice(0, -1);
}

/**
 * 将字符串转换为声音对象数组
 *
 * @param {string} m - 包含声音信息的字符串
 * @returns {Array} - 解析后的声音对象数组
 */
export function audioStringToObject(m) {
  const sounds = [];

  // 按照 '-' 分割字符串
  const parts = m.split('-');

  // 遍历每个部分并解析
  for (let i = 0; i < parts.length; i += 2) {
    const id = parseInt(parts[i], 10);
    const volumeStr = parts[i + 1];
    const volume = parseInt(volumeStr, 10);

    // 构建对象并添加到数组
    sounds.push({ id, volume });
  }

  return sounds;
}
/**
 * 将给定的时间戳格式化为年-月-日的日期字符串
 * @param {number|string|null|undefined} timestamp - 需要格式化的日期时间戳，可以是数字、字符串或空值
 * @returns {string|null} 格式化后的日期字符串，如 '2023-04-01'；如果输入无效则返回 null
 */
export function formatDate(timestamp) {
  // 检查 timestamp 是否为空或 undefined
  if (timestamp == null) {
    return null;
  }

  // 尝试将 timestamp 转换为数字
  let ts = Number(timestamp);

  // 如果转换失败且 timestamp 是字符串，尝试解析 ISO8601 格式的日期字符串
  if (isNaN(ts)) {
    if (typeof timestamp === 'string') {
      const date = new Date(timestamp);
      if (date.toString() === 'Invalid Date') {
        return null;
      }
      ts = date.getTime();
    } else {
      return null;
    }
  }

  // 如果 timestamp 是以秒为单位的时间戳，转换为毫秒
  if (ts < 1e12) {
    ts *= 1000;
  }

  // 创建一个 Date 对象，基于给定的时间戳
  const date = new Date(ts);

  // 获取年份
  const year = date.getFullYear();

  // 获取月份，由于 getMonth() 返回 0-11，因此需要加 1
  // 使用 padStart 确保月份为两位数，不足两位时在前面补 0
  const month = String(date.getMonth() + 1).padStart(2, '0');

  // 获取日期，即一个月中的某一天
  // 使用 padStart 确保日期为两位数，不足两位时在前面补 0
  const day = String(date.getDate()).padStart(2, '0');

  // 返回格式化的日期字符串
  return `${year}-${month}-${day}`;
}
