<template>
  <div class="other_login" :class="`${type}_login_icon`">
    <!-- <i id="login_btn" @click="qqLogin"></i> -->
    <div class="bindPhone" v-if="bindPhone">
      <div class="login-form">
        <i class="login-form-close" @click="closepop">×</i>
        <div class="login-form-title">绑定手机号</div>
        <div class="login-form-content">
          <div class="login-mobile-code">
            <div class="login-mobile-input-wrapper">
              <input
                type="tel"
                ref="phone"
                @blur="errmsgChange('phone')"
                @input="inputChange('phone')"
                v-model.number="phone"
                placeholder="请输入手机号"
                autocomplete="tel"
                maxlength="50"
                tabindex="0"
              />
            </div>
            <div class="login-code-input-wrapper">
              <div class="login-button-input">
                <span
                  class="login-button-input-text"
                  :class="{
                    'login-button-input-text_disabled': codeTimer && num !== 60,
                  }"
                  @click="getCode"
                  :disabled="codeTimer && num !== 60"
                  >{{ codemsg }}</span
                >
                <div class="login-button-input-wrapper">
                  <input
                    type="tel"
                    ref="code"
                    @blur="codeBlur"
                    @input="inputChange('code')"
                    v-model="code"
                    class="login-button-input__input"
                    placeholder="验证码"
                    autocomplete="off"
                    maxlength="50"
                    tabindex="0"
                  />
                </div>
              </div>
            </div>
            <div class="login-error">{{ errmsg }}</div>
            <div class="login-submit-btn-wrapper">
              <!-- :class="{ 'login-submit-btn-disabled': btnIsClick }" -->
              <button type="submit" @click="userLogin" class="">绑定</button>
            </div>

            <div class="login-confirm-info">
              <span
                @click="confirmInfoChange"
                :class="{
                  'login-confirm-info_checkbox_checked': confirmInfo,
                }"
                class="login-confirm-info_checkbox"
                role="checkbox"
              ></span>
              <span class="login-confirm-info__before-text">登录即同意</span>
              <router-link :to="{ name: 'UserAgreement' }">《用户协议》</router-link>
              <span class="login-confirm-info__before-text">和</span>
              <router-link :to="{ name: 'PrivacyAgreement' }">《隐私协议》</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WX_DATA } from '@/utils/baseConfig.js'
import { SET_TOKEN, SET_USER_INFO } from '@/utils/storage.js'
import { GET_CODE, PHONE_BIND } from '@/api/user.js'
import { getQueryVariable } from '@/utils/helper'
import { GET_USER_INFO } from '@/api/index'
export default {
  name: 'OtherLogin',
  props: ['type'],
  data() {
    return {
      bindPhone: false,
      phone: '',
      code: '',
      codemsg: '获取验证码',
      errmsg: '',
      confirmInfo: true, // 是否同意用户协议
      codeTimer: null, // 获取验证码定时器
      num: 60, // 验证码倒计时
      qqdata: {
        openid: '',
        nickname: '',
        avatar: ''
      },
      loginData: {}
    }
  },
  created() {
    this.loginData = WX_DATA
    let code = window.location.search
    if (code.indexOf('code=') > -1) {
      const params = getQueryVariable()
      code = params?.code
      const state = params?.state
      GET_USER_INFO({ code, state }).then((res) => {
        if (res.code === 200) {
          // 免责声明
          history.replaceState(null, null, location.origin + location.pathname)
          const shengming = window.localStorage.getItem('disclaimer')
          if (shengming === '0') {
            window.localStorage.removeItem('disclaimer')
          }
          if (res.data.bindstatus) {
            this.userInfo = res.data
            if (this.userInfo.id) {
              this.userInfo.user_id = this.userInfo.id
            } else {
              this.userInfo.id = this.userInfo.user_id
            }
            this.userInfo.token = res.data?.token
            SET_TOKEN(res.data?.token)
            SET_USER_INFO(JSON.stringify(this.userInfo))
            this.$store.commit('setUserInfo', this.userInfo)
            setTimeout(() => {
              this.$router.go(0)
            }, 200)
            // const lasturl = document.referrer
            // if (lasturl && lasturl.indexOf('qq.com') === -1 && lasturl.indexOf('/login') === -1) {
            //   window.open(lasturl, '_self')
            // } else {
            //   this.$router.push('/aihome')
            // }
          } else if (res.data?.openid) {
            this.qqdata.openid = res.data?.openid
            this.qqdata.nickname = res.data?.nickname
            this.qqdata.avatar = res.data?.avatar
            this.bindPhone = true
            this.$store.commit('setLoginShow', false)
            history.replaceState(null, null, location.origin + location.pathname)
          } else {
            history.replaceState(null, null, location.origin + location.pathname)
            this.$message.error(res.message || '登陆出错!')
          }
        }
      }).catch((err) => {
        history.replaceState(null, null, location.origin + location.pathname)
        window.console.log('------获取用户信息失败-------')
        this.$message.success('获取用户信息失败!请刷新重试')
        window.console.log(err)
      })
    } else if (this.$store.state.bindShow === 'bind') {
      this.bindPhone = true
    }
  },
  methods: {
    closepop() {
      this.bindPhone = false
      this.$store.commit('setLoginShow', false)
    },
    qqLogin() {
      window.location.replace(this.loginData.url)
      window.sessionStorage.setItem('other_login', this.type)
    },
    codeBlur() {
      if (this.code === '') {
        this.errmsg = '请输入验证码'
        return
      }
      if (this.code !== '' && this.code.length !== 6) {
        this.errmsg = '验证码只能为六位数字'
      }
    },
    getCode() {
      // 获取验证码
      if (this.codeTimer || !this.errmsgChange('code')) return
      GET_CODE({ phone: this.phone, scapp: 2 }).then(res => {
        if (res.code !== 200) {
          this.$message.warning(res.message || '获取验证码出错')
        }
      })
      this.num--
      this.codemsg = `${this.num}秒后重新获取`
      this.codeTimer = setInterval(() => {
        if (this.num < 1) {
          clearInterval(this.codeTimer)
          this.codeTimer = null
          this.num = 60
          this.codemsg = '获取验证码'
        } else {
          this.num--
          this.codemsg = `${this.num}秒后重新获取`
        }
      }, 1000)
      this.$refs.code.focus()
    },
    userLogin() {
      if (!this.errmsgChange()) return
      const data = { phone: this.phone, smscode: this.code, logintype: this.type === 'qq' ? 'qq' : 'weixin', ...this.qqdata }
      PHONE_BIND(data).then(res => {
        if (res.code === 108) {
          this.errmsg = res.message || '验证码过期或错误'
          return
        }
        this.bindPhone = false
        if (res.code === 200 && res.data) {
          const user = res.data
          SET_TOKEN(res.data?.token)
          if (user.id) {
            user.user_id = user.id
          } else {
            user.id = user.user_id
          }
          this.$store.commit('setUserInfo', user)
          SET_USER_INFO(JSON.stringify(user))
          this.$store.commit('setLoginShow', false)

          // 免责声明
          const shengming = window.localStorage.getItem('disclaimer')
          if (shengming === '0') {
            window.localStorage.removeItem('disclaimer')
          }
          this.$router.go(0)
          // const lasturl = document.referrer
          // if (lasturl && lasturl.indexOf('qq.com') === -1 && lasturl.indexOf('/login') === -1) {
          //   window.open(lasturl, '_self')
          // } else {
          //   this.$router.push('/aihome')
          // }
        } else {
          this.errmsg = '登录失败'
        }
      })
    },
    errmsgChange(type) {
      if (this.phone === '') {
        this.errmsg = '请输入手机号'
        return
      }
      if (type !== 'code' && type !== 'phone') {
        if (this.code === '') {
          this.errmsg = '请输入验证码'
          return
        }
        if (!this.confirmInfo) {
          this.errmsg = '请先勾选用户协议和隐私政策'
          return
        }
        if (this.code !== '' && this.code.length !== 6) {
          this.errmsg = '验证码只能为六位数字'
          return
        }
      }
      if (this.phone !== '') {
        const sj = /^[1]([3-9])[0-9]{9}$/
        if (sj.test(this.phone)) {
          this.errmsg = ''
          return true
        } else {
          this.errmsg = '手机号格式错误'
        }
      }
    },
    confirmInfoChange() {
      // 点击勾选同意用户协议事件
      this.confirmInfo = !this.confirmInfo
      this.errmsg = this.confirmInfo ? '' : '请先勾选用户协议和隐私政策'
    },
    inputChange(type) {
      // 替换输入框中的非数字
      try {
        this[type] = this[type].replace(/[^\d]/g, '')
      } catch (e) {
        //
      }
    }
  }
}
</script>

<style lang='less' scoped>
.other_login {
  width: 100%;
  height: 100%;
  > i {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .bindPhone {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 1);
    z-index: 2200;
    .login-form {
      position: absolute;
      // min-width: 55%;
      padding: 0.35rem 0.4rem 0.2rem;
      width: 4rem;
      min-height: 3.54rem;
      max-width: 6rem;
      margin: 0 auto;
      background-color: #fff;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 0.24rem;
      // &::after {
      //   content: "";
      //   display: block;
      //   width: 1px;
      //   height: 238px;
      //   background-color: #e8e8e8;
      //   position: absolute;
      //   right: 0;
      //   top: 0;
      // }
      .login-form-close {
        position: absolute;
        top: 0.1rem;
        right: 0.2rem;
        cursor: pointer;
        font-size: 0.32rem;
        color: #333;
        &:hover {
          opacity: 0.8;
        }
      }
      .login-form-title {
        font-weight: 500;
        line-height: 0.24rem;
        font-size: 0.2rem;
        font-weight: 700;
        color: #333;
        text-align: center;
        margin-bottom: 0.39rem;
      }
      .login-form-content {
        width: 100%;
        .login-mobile-code {
          .login-mobile-input-wrapper {
            height: 0.44rem;
            border-radius: 0.06rem;
            background-color: #fff;
            width: 100%;
            line-height: 0.22rem;
            padding: 0.12rem 0.16rem;
            box-sizing: border-box;
            border: 1px solid #e8e8e9;
            > input {
              width: 100%;
              height: 100%;
              line-height: 100%;
              font-size: 0.14rem;
              outline: none;
              -webkit-box-shadow: none;
              box-shadow: none;
              background-color: transparent;
              border: none;
            }
          }
          .login-code-input-wrapper {
            margin-top: 0.16rem;
            .login-button-input {
              display: flex;
              border: 1px solid #e8e8e9;
              justify-content: space-between;
              width: 100%;
              height: 0.44rem;
              line-height: 0.22rem;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              padding: 0.13rem 0.16rem;
              border-radius: 0.06rem;
              background-color: #fff;
              .login-button-input-text {
                order: 2;
                height: 0.2rem;
                line-height: 0.2rem;
                flex-shrink: 0;
                font-size: 0.14rem;
                color: #222;
                cursor: pointer;
              }
              .login-button-input-text_disabled {
                color: #999;
                cursor: default;
              }
              .login-button-input-wrapper {
                display: flex;
                flex-grow: 1;
                order: 1;
                .login-button-input__input {
                  outline: none;
                  -webkit-box-shadow: none;
                  box-shadow: none;
                  background-color: transparent;
                  border: none;
                  height: 100%;
                  line-height: 100%;
                  font-size: 0.14rem;
                  width: 100%;
                }
              }
            }
          }
          .login-error {
            width: 100%;
            height: 0.3rem;
            padding-top: 0.04rem;
            padding-left: 0.16rem;
            font-size: 0.12rem;
            line-height: 0.16rem;
            color: #ff5533;
            white-space: nowrap;
            outline: none;
          }
          .login-confirm-info {
            user-select: none;
            outline: none;
            line-height: 0.2rem;
            font-size: 0.12rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #999;
            .login-confirm-info_checkbox {
              display: inline-block;
              width: 0.16rem;
              height: 0.16rem;
              margin-right: 0.04rem;
              border-radius: 0.02rem;
              border: 1px solid #d9d9d9;
              vertical-align: text-bottom;
              cursor: pointer;
              margin-top: 0.04rem;
            }
            .login-confirm-info_checkbox_checked {
              border: none;
              background-repeat: no-repeat;
              background-position-x: 50%;
              background-size: 0.14rem 0.14rem;
              background-image: url("~@/assets/icons/checked.png");
            }
            .login-confirm-info__before-text {
              color: #999;
            }
            > a {
              color: #999;
            }
          }
          .login-submit-btn-wrapper {
            margin-top: 0.08rem;
            padding-bottom: 0.3rem;
            border-bottom: 1px solid #f5f6f9;
            margin-bottom: 0.16rem;
            > button {
              cursor: pointer;
              width: 100%;
              height: 0.44rem;
              border: none;
              outline: none;
              line-height: 0.44rem;
              font-weight: 500;
              border-radius: 0.04rem;
              font-size: 0.16rem;
              text-align: center;
              background-color: #ff5533;
              color: #fff;
              border-radius: 0.06rem;
            }
            > .login-submit-btn-disabled {
              background: rgba(240, 65, 66, 0.5);
              color: #fff;
              cursor: default;
            }
          }
        }
      }
    }
  }
}
</style>
