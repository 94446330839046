import Vue from "vue";
import App from "./App.vue";
import './styles/base.less'
import store from './store'
import router from './router'
import '@/plugins/message/index.js'

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount("#app");
