<template>
  <div class="home">
    <div class="home-top">
      <HomeHeader />
    </div>
    <div class="home-input">
      <HomeSearch />
    </div>
    <div class="home-content">
      <HomeContent />
    </div>
    <div class="home-footer">
      <HomeFooter />
    </div>
  </div>
</template>

<script>
import HomeContent from './home-content/home-content.vue'
import HomeFooter from './home-footer/home-footer.vue'
import HomeHeader from './home-header/home-header.vue'
import HomeSearch from './home-search/home-search.vue'
export default {
  components: { HomeHeader, HomeContent, HomeSearch, HomeFooter },
  name: 'HomePage',
  
}
</script>

<style lang='less' scoped>
.home {
  padding-bottom: 0.3rem;
  .home-content {
    margin-top: 0.8rem;
  }
}
</style>