<template>
  <div class="home-search" v-if="searchList && searchList.length > 0">
    <div class="search-layout">
      <div class="search-input-layout">
        <div
          class="search-select"
          :class="{ 'bottom-radius': selectShow || historyShow }"
          @click.stop="selectShowChange"
        >
          <div class="search-type-layout" v-if="currentSearch.iconName">
            <img class="search-type-img" :src="currentSearch.iconUrl" alt="" />
            <i class="down-icon" :class="{ 'up-icon': selectShow }"></i>
          </div>

          <!-- :class="
                currentSearch.iconName === i.iconName
                  ? 'search-type-item_active'
                  : ''
              " -->
          <ul class="search-type-list" v-if="selectShow">
            <li
              class="search-type-layout search-type-item"
              v-for="(i, x) in searchList"
              :key="x"
              @click.stop="selectChange(i)"
            >
              <img class="search-type-img" :src="i.iconUrl" alt="" />
              <span class="search-type-text">{{ i.iconName }}</span>
            </li>
          </ul>
        </div>
        <div class="input-layout">
          <input
            type="text"
            placeholder="请输入关键字"
            v-model="keyword"
            @keydown.enter="search('')"
            @click.stop="historyShowChange"
          />
        </div>

        <button
          class="search-bottom"
          :class="{ 'bottom-right-radius': historyShow }"
          @click="search('')"
        ></button>
      </div>

      <div class="search-history-layout" @click.stop v-if="historyShow">
        <div class="search-history-title">
          <span>最近搜索</span>
          <span
            v-if="historyList.length"
            class="search-history-clear"
            @click="clearHistory"
            >清空</span
          >
        </div>
        <ul class="search-history-list">
          <li
            class="search-history-item"
            v-for="(i, x) in historyList"
            :key="x"
            @click="search(i)"
          >
            <span class="search-history-text">{{ i }}</span>
            <i class="history-del" @click="delHistory(x)">×</i>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { GetSearchData } from '@/api/index'
export default {
  name: 'HomeSearch',
  data() {
    return {
      searchList: [],
      currentSearch: '',
      selectShow: false,
      keyword: '',
      historyList: [],
      historyShow: false
    }
  },
  created() {
    const searchKey = window.localStorage.getItem('search_key')
    if (searchKey) {
      try {
        this.currentSearch = JSON.parse(searchKey)
      } catch (error) {
        this.currentSearch = {}
        window.console.log(error)
      }
    }
    const historyKeys = window.localStorage.getItem('search_history')
    if (historyKeys) {
      try {
        this.historyList = JSON.parse(historyKeys)
      } catch (error) {
        window.console.log(error)
      }
    }
    this.getData();
    document.documentElement.addEventListener('click', () => {
      this.selectShow = false
      this.historyShow = false
    })
  },
  methods: {
    historyShowChange() {
      this.historyShow = true
      this.selectShow = false
    },
    clearHistory() {
      this.historyList = []
      window.localStorage.setItem('search_history', JSON.stringify(this.historyList))
    },
    delHistory(x) {
      this.historyList.splice(x, 1)
      window.localStorage.setItem('search_history', JSON.stringify(this.historyList))
    },
    setHistory(key) {
      if (this.historyList.includes(key)) {
        return
      }
      this.historyList.unshift(key)
      if(this.historyList.length > 10) {
        this.historyList.length = 10
      }
      window.localStorage.setItem('search_history', JSON.stringify(this.historyList))
    },
    search(keyword) {
      const key = (keyword || this.keyword).trim()
      if (!key) {
        return
      }
      const { searchLinkUrl } = this.currentSearch
      const searchUrl = searchLinkUrl.replace(/\{\{keyword\}\}/, encodeURIComponent(key))
      this.setHistory(key)
      window.open(searchUrl, '_blank')
      this.historyShow = false
    },
    selectShowChange() {
      this.historyShow = false
      this.selectShow = !this.selectShow
    },
    selectChange(i) {
      this.currentSearch = i
      this.selectShow = false
      window.localStorage.setItem('search_key', JSON.stringify(i))
    },
    async getData() {
      const res = await GetSearchData()
      if (res.code === 200 && res.data?.length > 0) {
        this.searchList = res.data
        if (!this.currentSearch) {
          this.currentSearch = res.data[0]
        }
      }
    }
  }
}
</script>

<style lang='less' scoped>
@inputHeight: 0.5rem;
@inputBorder: 1px solid rgba(255, 255, 255, 0.19);
.home-search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.6rem;
  .search-layout {
    width: 7.2rem;
    height: @inputHeight;
    position: relative;

    .search-input-layout {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      // background-color: #fff;
      border-radius: 0.08rem;
    }
    .search-select {
      width: 0.87rem;
      height: 100%;
      position: relative;
      border: @inputBorder;
      border-right: none;
      // border-radius: 0.08rem 0 0 0.08rem;
      border-radius: 0.25rem 0 0 0.25rem;
      &::before {
        content: " ";
        position: absolute;
        right: 0;
        width: 1px;
        height: 0.24rem;
        background-color: #6c7277;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .search-type-layout {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .search-type-img {
        width: 0.32rem;
        height: 0.32rem;
      }
      .search-type-text {
        font-size: 0.14rem;
        color: #fff;
        margin-left: 0.12rem;
      }

      .down-icon {
        width: 0.2rem;
        height: 0.2rem;
        background-image: url("~@/assets/icons/icon_down.png");
        background-size: 0.22rem 0.22rem;
        background-repeat: no-repeat;
        transition: transform 0.3s ease;
        transform: rotate(0deg);
        margin-left: 0.08rem;
      }
      .up-icon {
        transform: rotate(180deg);
      }
    }
    .search-type-list {
      width: 1.28rem;
      min-height: @inputHeight;
      background-color: rgba(245, 245, 245, .1);
      position: absolute;
      left: -0.01rem;
      top: @inputHeight - 0.01rem;
      border: @inputBorder;
      border-top: none;
      border-radius: 0 0 0.08rem 0.08rem;
      padding: 0.1rem 0;
      overflow: hidden;
      .search-type-item {
        padding: 0.08rem 0.32rem 0.08rem 0.16rem;
        &:hover {
          background-color: rgba(255, 255, 255, .1);
        }
      }
      .search-type-item_active {
        background-color: rgba(255, 255, 255, .1);
      }
    }

    .input-layout {
      flex: 1;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      border: @inputBorder;
      border-left: none;
      border-right: none;
      > input {
        width: 100%;
        height: 100%;
        padding: 0 0.12rem;
        font-size: 0.16rem;
        background-color: transparent;
        outline: none;
        color: #fff;
      }
    }
    .search-bottom {
      width: 1rem;
      height: 100%;
      border: @inputBorder;
      border-left: none;
      cursor: pointer;
      background-image: url(~@/assets/icons/search-icon.png);
      background-repeat: no-repeat;
      background-position: 0.5rem 50%;
      background-size: 0.3rem 0.3rem;
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }
  .search-layout .bottom-radius {
    border-bottom-left-radius: 0;
  }
  .search-layout .bottom-right-radius {
    border-bottom-right-radius: 0;
    // border-top-right-radius: 0;
  }

  .search-history-layout {
    width: 100%;
    min-height: 0.4rem;
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: @inputHeight;
    left: 0;
    right: 0;
    border: @inputBorder;
    border-top: none;
    color: rgba(255, 255, 255, 1);
    padding-bottom: 0.14rem;
    z-index: 2;
    .search-history-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.14rem 0.14rem 0;
      font-weight: 700;
      font-size: 0.14rem;
      .search-history-clear {
        cursor: pointer;
        padding: 0 0.14rem;
        color: #fff;
      }
    }
    .search-history-list {
      margin-top: 0.1rem;
      .search-history-item {
        width: 100%;
        padding: 0.1rem 0.28rem;
        cursor: pointer;
        font-size: 0.12rem;
        position: relative;
        &:hover {
          background-color: rgba(245, 245, 245, .1);
        }
      }
      .history-del {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0.28rem;
        height: 0.28rem;
        cursor: pointer;
        font-size: 0.14rem;
        color: #fff;
        line-height: 0.28rem;
        text-align: center;
      }
    }
  }
}
</style>