import { http } from '@/utils/http'

// 4.01获取商品列表
export function GOODS_LIST (data) {
  return http({
    url: 'na/pay/goodslist',
    method: 'post',
    data
  })
}

// 4.02-选择商品预支付
export function CHOOSE_GOODS (data) {
  return http({
    url: 'na/pay/prepay',
    method: 'post',
    data
  })
}

// 4.03获取支付状态
export function PAY_STATUS (data) {
  return http({
    url: 'na/pay/paystatus',
    method: 'post',
    data
  })
}
