<template>
  <div class="home-header">
    <div class="header-left">
      <span class="slide-bar">
        <WhiteSlider v-model="volume" @change="volumeChange" />
      </span>
      <span>音量</span>
    </div>
    <div class="header-right">
      <nav>
        <!-- <a href="javascript:;">获取更新</a> -->
        <a href="https://hao.needmust.cn/aihome" title="必备AI">必备AI</a>
        <a href="https://hao.needmust.cn/" title="必备导航">必备导航</a>
        <a href="/agreement/AboutUs" title="关于">关于</a>
        <a
          v-if="!userInfo.avatar"
          href="javascript:;"
          @click.prevent="loginShow"
          >登录</a
        >
        <div class="user-cover" @click="modalChange(true)" v-else>
          <img :src="userInfo.avatar" alt="" />
          <!-- <span @click.stop="loginOut">退出</span> -->
        </div>
      </nav>
    </div>

    <UserModal v-if="userModal" :info="userInfo" @loginOut="loginOut" @modalClose="modalChange(false)" />
  </div>
</template>

<script>
import WhiteSlider from '@/components/white-slider/white-slider.vue'
import { REMOVE_TOKEN, REMOVE_USER_INFO } from '@/utils/storage'
import UserModal from '@/components/user-modal/user-modal.vue'
export default {
  name: 'HomeHeader',
  components: { WhiteSlider, UserModal },
  data() {
    return {
      volume: 100,
      userInfo: {},
      userModal: false
    }
  },
  watch: {
    '$store.state.userInfo': {
      handler(v) {
        this.userInfo = v
      },
      deep: true
    }
  },
  mounted() {
    this.volume = this.$store.state.volume
    this.userInfo = this.$store.state?.userInfo || {}
  },
  methods: {
    modalChange(val) {
      this.userModal = val
    },
    volumeChange(v) {
      this.$store.commit('setVolume', v)
    },
    loginShow() {
      this.$store.commit('setLoginShow', true)
    },
    loginOut() {
      REMOVE_TOKEN()
      REMOVE_USER_INFO()
      this.$store.commit('setUserInfo', {})
      this.$router.go(0)
    }
  }
}
</script>

<style lang='less' scoped>
.home-header {
  padding: 0.15rem 1rem;
  display: flex;
  justify-content: space-between;
  .header-left {
    width: 1.68rem;
    display: flex;
    align-items: center;
    font-size: 0.12rem;
    .slide-bar {
      flex: 1;
      margin-right: 0.24rem;
      /deep/ input[type="range"] {
        &::-webkit-slider-thumb {
          width: 0.16rem;
          height: 0.16rem;
          margin-top: -0.07rem;
        }
        &::-webkit-slider-runnable-track {
          height: 0.02rem;
        }
      }
    }
  }
  .header-right {
    max-width: calc(100% - 1.68rem);
    min-height: 0.32rem;
    display: flex;
    align-items: center;
    > nav {
      display: flex;
      align-items: center;
      a {
        font-size: 0.14rem;
        color: #fff;
        text-decoration: none;
        margin-left: 0.32rem;
      }
      .user-cover {
        margin-left: 0.32rem;
        display: flex;
        align-items: center;
        position: relative;
        height: 0.32rem;
        padding-right: 0.22rem;
        > img {
          width: 0.32rem;
          border-radius: 50%;
          cursor: pointer;
        }
        > span {
          display: none;
          position: absolute;
          cursor: pointer;
          right: -0.14rem;
          font-size: 0.16rem;
        }
        &:hover {
          > span {
            display: block;
          }
        }
      }
    }
  }
}
</style>