import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: () => import('@/views/agreements/index.vue'),
    redirect: { name: 'PrivacyStatement' },
    children: [
      {
        // 用户协议
        path: 'UserAgreement',
        name: 'UserAgreement',
        component: () => import('@/views/agreements/user-agreement.vue')
      },
      {
        // 隐私声明
        path: 'PrivacyStatement',
        name: 'PrivacyStatement',
        component: () => import('@/views/agreements/privacy-statement.vue')
      },
      {
        // 使用条款
        path: 'TermsOfUse',
        name: 'TermsOfUse',
        component: () => import('@/views/agreements/terms-of-use.vue')
      },
      {
        // 关于我们
        path: 'AboutUs',
        name: 'AboutUs',
        component: () => import('@/views/agreements/about-us.vue')
      },
      {
        // 充值协议
        path: 'RechargeAgreement',
        name: 'RechargeAgreement',
        component: () => import('@/views/agreements/recharge-agreement.vue')
      },
      {
        // 隐私协议
        path: 'PrivacyAgreement',
        name: 'PrivacyAgreement',
        component: () => import('@/views/agreements/privacy-agreement.vue')
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
